#moveableBlocksWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Web size */
@media (min-width: 450px) {
  .phone-wrapper {
    position: absolute;
    box-sizing: border-box;
    width: 420px;
    height: 720px;
    top: calc(50vh - 600px/2 - 60px);
    left: calc(50vw - 300px/2 - 60px);
    border: solid 60px transparent;
    /* outline: solid 1px #0f0; */
  }
  
  .phone-image {
    width: 100%;
    height: 100%;
    background: url('../../../shared/images/iphone10.png') center center no-repeat;
    background-size: contain;
  }
  
  .iframe-container {
    position: absolute;
    top: 16px;
    bottom: 18px;
    left: 19px;
    right: 18px;
    border-radius: 25px;
    overflow: hidden;
    /* outline: solid 1px #00f; */
  }
  
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
    /* outline: 1px solid #f00; */
  }
}

/* Phone size */
@media (max-width: 449px) {
  .phone-wrapper {
    position: absolute;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    transform: initial !important;
  }
  
  .phone-image {
    display: none;
  }
  
  .iframe-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.retroTheme {
  font-family: Arial, Helvetica, sans-serif;
  background: #c4ccd5 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAABCAYAAAASC7TOAAAAGklEQVR4Xm3DMREAAACCQKPYvySeO8MHML0BOrEVLMJmdzAAAAAASUVORK5CYII=);
  
  .top-bar {
    color: #fff;
    background: #506d93;
    background-image: -webkit-gradient(linear,50% 0,50% 100%,color-stop(0,rgba(255,255,255,0.65)),color-stop(1,rgba(255,255,255,0)));
    border-bottom: solid 1px #738498;
    box-shadow: rgba(0,0,0,0.3) 0 -1px 0 inset,  rgba(0,0,0,0.3) 0 1px 3px;

    .circle-icon {
      display: none;
    }

    .top-bar-title {
      display: block;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 -1px rgba(0,0,0,0.6);
    }
  }
  
  .content-area {
    padding: 10px 5px;
  }
  
  .bottom-bar {
    background: none;
    border: none;
    align-items: center;
    border-top: solid 1px #000;
    
    .bottom-tab {
      padding: 6px;
      background: #fff;
      background-color: #1a1a1a;
      text-shadow: 0 1px 1px #000;
      background-image: -webkit-gradient(linear,50% 0,50% 100%,color-stop(0,rgba(255,255,255,0.18)),color-stop(1,rgba(255,255,255,0)));
      border-top: 1px solid rgba(255,255,255,0.2);
      border-right: solid 1px #000;

      &:hover {
        background-color: #1a1a1a;
        background-image: -webkit-gradient(linear,50% 0,50% 100%,color-stop(0,rgba(255,255,255,0)),color-stop(1,rgba(255,255,255,0.18))),-webkit-gradient(linear,0 50%,100% 50%,color-stop(0,rgba(0,0,0,0.6)),color-stop(0.03,rgba(0,0,0,0)),color-stop(0.97,rgba(0,0,0,0)),color-stop(1,rgba(0,0,0,0.6)));
      }
        
      i {
        font-size: 20px;

        &:hover {
          color: #333;
        }
      }

      .bottom-tab-text {
        color: #fff;
        text-shadow: 1px 1px 0px #3d5056;  
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  
  .button {
    border-color: #777;
    margin-top: 20px;
    background: #eef;
    opacity: 0.8;
  }

  h3 {
    padding: 8px 16px;
    margin-right: 30px;
    border-radius: 12px 12px 12px 0;
    color: #111;
    background: linear-gradient(to bottom, #777 0%,#eee 100%);
    border: solid 1px #999;
    box-shadow: inset 0px 20px 10px 0px rgba(255,255,255,0.75);
  }
  
  .card {
    margin-left: 30px;
    border-radius: 12px 12px 0 12px;
    color: #062c00;
    background: linear-gradient(to bottom, #3c7822 0%,#d6d6d6 0%,#3c7822 10px,#b7d86d 100%);
    border-color: #6e8477;

    .card-header {
      border: none;
    }
  }

  .logo-big i {
    color: #555;
  }

  .contact-link i,
  .card i {
    color: #fff;
    opacity: 0.6;
  }
}
.blueTheme {
  color: #555;

  i {
    color: #003eb8;
  }
  
  .top-bar {
    border-color: #aaa;

    .circle-icon {
      background-color: #003eb8;
    }
  }
  
  .content-area {
    background: #eee;
  }
  
  .bottom-bar {
    font-size: 12px;
    border-color: #aaa;
    
    i { 
      font-size: 18px; 
    }
    
    .bottom-tab {
      
      &:hover {
        font-weight: 400;
        color: #333;
      }
    }
  }
  
  .button {
    color: #fff !important;
    background: #003eb8;
    border-color: #aaa;
    
    &:hover {
      color: #555 !important;
      background: #fff;
      border-color: #aaa;
    }
  }
  
  .card {
    border: none;
    border-bottom: dotted 1px #888;

    .card-header {
      border: none;
    }
  }
  
  a {
    &:hover {
      color: #003eb8;
    }
  }
}
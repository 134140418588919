.top-bar {
  .circle-icon {
    display: inline-block;
    padding: 0 0.30em;
    border-radius: 50%;
    cursor: pointer;
  
    font-size: 30px;
    text-align: center;
    line-height: 1.5em;
  }
}

.inner-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  zoom: 0.90;
}

.content-area {
  position: relative;
  padding: 10px;
}

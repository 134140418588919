.flex-rows {
  display: flex;
  flex-direction: column;

  > * {
    overflow-x: hidden;
    overflow-y: auto;
    flex-shrink: 0;
  }
}

.flex-cols {
  display: flex;
  flex-direction: row;
  
  > * {
    overflow-x: hidden;
    overflow-y: auto;
    flex-shrink: 0;
  }
}

.flex-grow-1 {
  flex-grow: 1; 
  flex-shrink: initial;
}
